import React, {useEffect, useState} from 'react';
import {navigate} from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import FeaturedImage from "@js/component/FeaturedImage";
import Heading from "@js/component/Heading";
import Box from "@js/component/Box";
import Main from "@js/component/Main";
import clsx from "clsx";

import * as classNames from "@css/component/template/wordpress/Page.module.scss";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import ChevronLeftIcon from "@js/component/icon/ChevronLeftIcon";
import NewsCards from "@js/component/NewsCards";
import BreadCrumbs from "@js/component/BreadCrumbs";

/**
 * @type PageProps
 */
type PageProps = {
    pageContext: {
        content: string;
        currentPage: number;
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            };
        };
        seo: {
            breadcrumbs: {
                text: string;
                url: string;
            }[];
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            title: string;
            twitterDescription: string;
            twitterTitle: string;
            schema: {
                raw: string;
            };
        };
        title: string;
        totalPages: number;
        posts: [];
    };
};

const NewsArchive = (props: PageProps) => {
    const {
        pageContext
    } = props;

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (page: number) => {
        navigate(`/labelnieuws/${ (page > 1 ? page : '') }`);
    }

    useEffect(() => {
        setCurrentPage(pageContext.currentPage - 1);
    });

    const OpenGraphImage = process.env.SITE_URL + pageContext.featuredImage?.node.localFile.publicURL;

    return (
        <Main>
            <Helmet>
                <title>{ pageContext.title }</title>

                { pageContext.seo.metaKeywords && <meta content={ pageContext.seo.metaKeywords } name="keywords" /> }
                { pageContext.seo.metaDesc && <meta content={ pageContext.seo.metaDesc } name="description" /> }

                <meta content={ `${ pageContext.seo.metaRobotsNofollow }, ${ pageContext.seo.metaRobotsNoindex }` } name="robots" />

                { pageContext.seo.opengraphDescription && <meta content={ pageContext.seo.opengraphDescription } property="og:description" /> }
                { pageContext.seo.opengraphModifiedTime && <meta content={ pageContext.seo.opengraphModifiedTime } property="article:modified_time" /> }
                { pageContext.seo.opengraphPublishedTime && <meta content={ pageContext.seo.opengraphPublishedTime } property="article:published_time" /> }
                { pageContext.seo.opengraphPublisher && <meta content={ pageContext.seo.opengraphPublisher } property="article:author" /> }
                { pageContext.seo.opengraphSiteName && <meta content={ pageContext.seo.opengraphSiteName } property="og:site_name" /> }
                { pageContext.seo.opengraphSiteName && <meta content={ pageContext.seo.opengraphSiteName } property="og:site_name" /> }
                { pageContext.seo.opengraphTitle && <meta content={ pageContext.seo.opengraphTitle } property="og:title" /> }
                { pageContext.seo.opengraphType && <meta content={ pageContext.seo.opengraphType } property="og:type" /> }
                { pageContext.seo.opengraphUrl && <meta content={ pageContext.seo.opengraphUrl } property="og:url" /> }

                <meta property="og:image" content={ OpenGraphImage } />
                <meta property="og:image:type" content={ pageContext.featuredImage?.node.mimeType } />
                <meta property="og:image:width" content={ pageContext.featuredImage?.node.width.toString() } />
                <meta property="og:image:height" content={ pageContext.featuredImage?.node.height.toString() } />
                <meta property="og:image:alt" content={ pageContext.featuredImage?.node.altText } />

                { pageContext.seo.twitterDescription && <meta content={ pageContext.seo.twitterDescription } name="twitter:description" /> }
                { pageContext.seo.twitterTitle && <meta content={ pageContext.seo.twitterTitle } name="twitter:title" /> }

                <script type="application/ld+json" className="yoast-schema-graph">
                    { pageContext.seo.schema.raw }
                </script>
            </Helmet>
            <FeaturedImage
                className={ classNames.featuredImage }
                gatsbyImageData={ pageContext.featuredImage?.node.localFile?.childImageSharp.gatsbyImageData }
            />
            <div className={ classNames.container }>
                <BreadCrumbs
                    breadCrumbs={ pageContext.seo.breadcrumbs }
                />
                <article className={ clsx(classNames.article, classNames.news) }>
                    <Heading className={ classNames.heading } element="h1" variant="large">
                        { pageContext.title }
                    </Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockFullPage }>
                            <Box className={ classNames.box } dangerouslySetInnerHTML={{ __html: pageContext.content }} />
                        </div>
                    </div>
                </article>

                <NewsCards
                    posts={ pageContext.posts }
                />

                {pageContext.totalPages > 1 &&
                <div className={classNames.paginate}>
                    <ReactPaginate
                        activeLinkClassName={classNames.active}
                        breakLinkClassName={classNames._break}
                        containerClassName={classNames.list}
                        disabledClassName={classNames.disabled}
                        hrefBuilder={ (currentPage) =>  currentPage }
                        hrefAllControls={ true }
                        forcePage={currentPage}
                        marginPagesDisplayed={1}
                        nextLinkClassName={classNames.next}
                        nextLabel={<ChevronRightIcon/>}
                        onPageChange={({selected}) => {
                            setCurrentPage(selected + 1);
                            handlePageClick(selected + 1);
                        }}
                        pageClassName={classNames.listItem}
                        pageCount={pageContext.totalPages}
                        pageLinkClassName={classNames.anchor}
                        pageRangeDisplayed={4}
                        previousLinkClassName={classNames.previous}
                        previousLabel={<ChevronLeftIcon/>}
                    />
                </div>
                }
            </div>
        </Main>
    )
}

export default NewsArchive;